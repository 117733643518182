import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Grid, ArrowRight, Heart, BookOpen } from 'react-feather'
import { Delaunay } from 'd3-delaunay'
import seedrandom from 'seedrandom'

import Div from '../elements/Div'
import H4 from '../elements/H4'
import P from '../elements/P'
import H5 from '../elements/H5'
import Textarea from '../elements/Textarea'
import Input from '../elements/Input'
import Span from '../elements/Span'
import Flex from '../components/Flex'
import SectionBorder from '../components/SectionBorder'
import Lock from '../components/Lock'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import Color from '../components/controls/Color'
import Hidden from '../components/hidden'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomDiv from '../lib/generate/delaunay'

import { db } from '../lib/client'
import { UP_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const Main = ({ div }) => {
  const delaunay = Delaunay.from(div.coordinates.slice(1, div.pointsRangeMax))
  const voronoi = delaunay.voronoi([0, 0, div.viewBoxWidth, div.viewBoxHeight])

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '16rem',
        height: '100%',
        maxHeight: '100vh',
        padding: 64,
        backgroundColor: div.parentBg,
        color: div.color,
        width: '100%'
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={
          div.xValue +
          ' ' +
          div.yValue +
          ' ' +
          div.viewBoxWidth +
          ' ' +
          div.viewBoxHeight
        }
        width={div.width + '%'}
        strokeLinejoin="round"
        height={div.height + '%'}
        css={{ maxWidth: '100%', maxHeight: '100%' }}
      >
        <path
          stroke="currentColor"
          strokeLinejoin="round"
          strokeWidth={div.strokeWidth + 'px'}
          strokeOpacity={div.strokeOpacity / 100}
          fill="transparent"
          d={voronoi.render()}
          strokeDasharray={
            div.dashArray1 +
            ' ' +
            div.dashArray2 +
            ' ' +
            div.dashArray3 +
            ' ' +
            div.dashArray4
          }
        />
        <path
          stroke="currentColor"
          strokeLinejoin="round"
          strokeWidth={div.borderWidth + 'px'}
          fill="transparent"
          d={voronoi.renderBounds()}
        />
        <path
          stroke="currentColor"
          strokeWidth={div.pointStrokeWidth + 'px'}
          fill="transparent"
          d={delaunay.renderPoints()}
        />
      </svg>
    </div>
  )
}

const GridItem = ({ div, ...props }) => {
  const delaunay = Delaunay.from(div.coordinates.slice(1, div.pointsRangeMax))
  const voronoi = delaunay.voronoi([0, 0, div.viewBoxWidth, div.viewBoxHeight])

  return (
    <div
      css={{
        width: '33.333%',
        padding: '32px',
        height: '33.33vh',
        backgroundColor: div.parentBg
      }}
      {...props}
    >
      <a
        css={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          color: div.color
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={
            div.xValue +
            ' ' +
            div.yValue +
            ' ' +
            div.viewBoxWidth +
            ' ' +
            div.viewBoxHeight
          }
          width="100%"
          height="100%"
          css={{ maxWidth: '100%', maxHeight: '100%' }}
        >
          <path
            stroke="currentColor"
            strokeWidth={div.strokeWidth + 'px'}
            fill="transparent"
            d={voronoi.render()}
          />
          <path
            stroke="currentColor"
            strokeWidth={div.borderWidth + 'px'}
            fill="transparent"
            d={voronoi.renderBounds()}
          />
          <path
            stroke="currentColor"
            strokeWidth={div.pointStrokeWidth + 'px'}
            fill="transparent"
            d={delaunay.renderPoints()}
          />
        </svg>
      </a>
    </div>
  )
}

const GridView = ({ pins, onSelect }) => (
  <Flex
    css={{
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    }}
  >
    {Array(9)
      .fill(0)
      .map((_, i) => {
        const baseDiv = randomDiv()
        const divWithPins = { ...baseDiv, ...pins }
        const div = {
          ...divWithPins
        }

        return <GridItem key={i} onClick={() => onSelect(div)} div={div} />
      })}
  </Flex>
)

const Sidebar = ({ onChange, value, pins, onPin }) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }

  const delaunay = Delaunay.from(
    value.coordinates.slice(1, value.pointsRangeMax)
  )
  const voronoi = delaunay.voronoi([
    0,
    0,
    value.viewBoxWidth,
    value.viewBoxHeight
  ])

  return (
    <>
      <SidebarHeading>Voronoi Patterns</SidebarHeading>
      <Color
        label="Color"
        value={value.color}
        onChange={changeValue('color')}
        active={pins.color}
        onLock={() => onPin('color', value.color)}
        width={128}
      />
      <Color
        label="Background"
        value={value.parentBg}
        onChange={changeValue('parentBg')}
        active={pins.parentBg}
        onLock={() => onPin('parentBg', value.parentBg)}
        width={128}
      />
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.pointsRangeMax}
          onClick={() => onPin('pointsRangeMax', value.pointsRangeMax)}
        />
        <Label>Points</Label>
        <Input
          min={0}
          max={4096}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.pointsRangeMax)}
          type="range"
          onChange={changeValue('pointsRangeMax')}
        />
        <Input
          width="64px"
          value={Number(value.pointsRangeMax)}
          type="number"
          onChange={changeValue('pointsRangeMax')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.strokeWidth}
          onClick={() => onPin('strokeWidth', value.strokeWidth)}
        />
        <Label>Line Width</Label>
        <Input
          min={0}
          max={24}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.strokeWidth)}
          type="range"
          onChange={changeValue('strokeWidth')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          blur={0}
          fontSize={0}
          children={value.strokeWidth}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderWidth}
          onClick={() => onPin('borderWidth', value.borderWidth)}
        />
        <Label>Border Width</Label>
        <Input
          min={0}
          max={32}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.borderWidth)}
          type="range"
          onChange={changeValue('borderWidth')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          blur={0}
          fontSize={0}
          children={value.borderWidth}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.pointStrokeWidth}
          onClick={() => onPin('pointStrokeWidth', value.pointStrokeWidth)}
        />
        <Label>Point Stroke</Label>
        <Input
          min={0}
          max={32}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.pointStrokeWidth)}
          type="range"
          onChange={changeValue('pointStrokeWidth')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          blur={0}
          fontSize={0}
          children={value.pointStrokeWidth}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.strokeOpacity}
          onClick={() => onPin('strokeOpacity', value.strokeOpacity)}
        />
        <Label>Stroke Opacity</Label>
        <Input
          min={0}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.strokeOpacity)}
          type="range"
          onChange={changeValue('strokeOpacity')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          blur={0}
          fontSize={0}
          children={value.strokeOpacity}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.dashArray1}
          onClick={() => onPin('dashArray1', value.dashArray1)}
        />
        <Label>Dash Array</Label>
        <Input
          min={0}
          max={32}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.dashArray1)}
          type="range"
          onChange={changeValue('dashArray1')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          blur={0}
          fontSize={0}
          children={value.dashArray1}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.dashArray2}
          onClick={() => onPin('dashArray2', value.dashArray2)}
        />
        <Label>Dash Array</Label>
        <Input
          min={0}
          max={32}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.dashArray2)}
          type="range"
          onChange={changeValue('dashArray2')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          blur={0}
          fontSize={0}
          children={value.dashArray2}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.dashArray3}
          onClick={() => onPin('dashArray3', value.dashArray3)}
        />
        <Label>Dash Array</Label>
        <Input
          min={0}
          max={32}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.dashArray3)}
          type="range"
          onChange={changeValue('dashArray3')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          blur={0}
          fontSize={0}
          children={value.dashArray3}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.dashArray4}
          onClick={() => onPin('dashArray4', value.dashArray4)}
        />
        <Label>Dash Array</Label>
        <Input
          min={0}
          max={32}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.dashArray4)}
          type="range"
          onChange={changeValue('dashArray4')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          blur={0}
          fontSize={0}
          children={value.dashArray4}
        />
      </Flex>
      <SectionBorder my={3} />
      <H5 fontSize={0} mb={2}>
        Size
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.width}
          onClick={() => onPin('width', value.width)}
        />
        <Label>Width</Label>
        <Input
          width={1}
          steps={1}
          mr={2}
          min={0}
          max={100}
          value={Number(value.width)}
          type="range"
          onChange={changeValue('width')}
        />
        <Input
          width="64px"
          mr={2}
          value={Number(value.width)}
          type="number"
          onChange={changeValue('width')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.height}
          onClick={() => onPin('height', value.height)}
        />
        <Label>Height</Label>
        <Input
          width={1}
          steps={1}
          mr={2}
          min={0}
          max={100}
          value={Number(value.height)}
          type="range"
          onChange={changeValue('height')}
        />
        <Input
          width="64px"
          mr={2}
          value={Number(value.height)}
          type="number"
          onChange={changeValue('height')}
        />
      </Flex>
      <SectionBorder my={3} />
      <H5 fontSize={0} mb={2}>
        View Box
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.viewBoxWidth}
          onClick={() => onPin('viewBoxWidth', value.viewBoxWidth)}
        />
        <Label>Width</Label>
        <Input
          steps={1}
          width={1}
          mr={2}
          value={Number(value.viewBoxWidth)}
          type="number"
          onChange={changeValue('viewBoxWidth')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.height}
          onClick={() => onPin('viewBoxHeight', value.viewBoxHeight)}
        />
        <Label>Height</Label>
        <Input
          steps={1}
          width={1}
          mr={2}
          value={Number(value.viewBoxHeight)}
          type="number"
          onChange={changeValue('viewBoxHeight')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.xValue}
          onClick={() => onPin('xValue', value.xValue)}
        />
        <Label>Min X</Label>
        <Input
          steps={1}
          width={1}
          value={Number(value.xValue)}
          type="number"
          onChange={changeValue('xValue')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.yValue}
          onClick={() => onPin('yValue', value.yValue)}
        />
        <Label>Min Y</Label>
        <Input
          steps={1}
          width={1}
          value={Number(value.yValue)}
          type="number"
          onChange={changeValue('yValue')}
        />
      </Flex>
      <SectionBorder my={4} />
      <H5 mb={1}>Svg</H5>
      <Textarea
        bg="transparent"
        height={128}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={
          ` <svg color='${value.color}' viewBox='${value.xValue} ${value.yValue} ${value.viewBoxWidth} ${value.viewBoxHeight}' width='100%' height='${value.height}%'> \n` +
          ` <path stroke="currentColor" strokeWidth='${
            value.strokeWidth
          }px' fill="transparent" d='${voronoi.render()}' strokeDasharray='${
            value.dashArray1
          } ${value.dashArray2} ${value.dashArray3} ${
            value.dashArray4
          }' /> \n ` +
          ` <path stroke="currentColor" strokeWidth='${
            value.borderWidth
          }' fill="transparent" d='${voronoi.renderBounds()}' /> \n` +
          ` <path stroke="currentColor" strokeWidth='${
            value.pointStrokeWidth
          }' fill="transparent" d='${delaunay.renderPoints()}' /> \n` +
          `</svg>`
        }
      />
    </>
  )
}

export default ({ initialDiv, initialPins }) => {
  let votes = []
  try {
    votes = JSON.parse(localStorage.getItem('textImageVotes')) || []
  } catch (e) {}

  const [div, setDiv] = useState(initialDiv || randomDiv())
  const [pins, setPins] = useState(initialPins || {})
  const [gridView, setGridView] = useState(false)

  const divWithPins = { ...div, ...pins }
  const fullDiv = {
    ...divWithPins
  }

  const upvote = async () => {
    if (gridView) {
      return
    }

    const { id } = await db
      .collection('components')
      .doc('textImages')
      .collection('votes')
      .add({
        up: true,
        data: fullDiv,
        metadata: { pins }
      })

    localStorage.setItem(
      'textImagesVotes',
      JSON.stringify(
        [
          {
            id
            //backgroundColor: fullGradient.backgroundColor,
            //backgroundImage: fullGradient.backgroundImage
          },
          ...votes
        ]
          .slice(0, 100)
          .filter(Boolean)
      )
    )

    setDiv(randomDiv())
  }
  const skip = () => {
    setDiv(randomDiv())
  }
  const toggleGridView = () => {
    setGridView(!gridView)
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === RIGHT_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      upvote()
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  const handleGridSelection = div => {
    setDiv(div)
    setGridView(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={
        gridView ? (
          <GridView pins={pins} onSelect={handleGridSelection} />
        ) : (
          <Main div={fullDiv} />
        )
      }
      sidebar={
        <Sidebar
          value={fullDiv}
          pins={pins}
          onChange={(key, value) => {
            setDiv({
              ...div,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <div
            css={{
              color: 'black',
              textAlign: 'center',
              //padding: 20,
              display: 'flex',
              width: '100%',
              alignItems: 'center'
              //borderBottom: '1px solid rgba(0,0,0,.1)'
            }}
          >
            {false && (
              <Link
                to="/div/votes"
                css={{
                  color: 'black',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textDecoration: 'none'
                }}
              >
                <BookOpen size={20} />
                <span css={{ paddingLeft: 10 }}>Previous likes</span>
              </Link>
            )}
          </div>
          <Flex>
            <VoteButton width={1 / 2} title="Next" onClick={skip}>
              <ArrowRight size={20} />
              <Hidden>Next</Hidden>
            </VoteButton>
            <VoteButton
              width={1 / 2}
              title="Grid view"
              onClick={toggleGridView}
            >
              <Grid size={20} />
              <Hidden>Grid view</Hidden>
            </VoteButton>
          </Flex>
        </>
      }
    />
  )
}
