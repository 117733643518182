const randomHex = require('random-hex-color')
const chroma = require('chroma-js')
const sample = require('lodash.sample')
const adaptiveGradient = require('adaptive-gradient').default
const { randomInt } = require('../util')
const randomBoxShadow = require('./box-shadow')
const { Delaunay } = require('d3-delaunay')
const seedrandom = require('seedrandom')

const generateDiv = color => {
  color = color || randomHex()
  let parentBg = randomHex()

  const pointsCount = 9192
  const seed = sample(['skullcat', 'rad', 'sparkle', 'fun', 'alpine'])
  const height = 100
  const width = 100
  const viewBoxHeight = randomInt(320, 1080)
  const viewBoxWidth = randomInt(viewBoxHeight, viewBoxHeight * 2)
  const strokeWidth = randomInt(1, 4)

  const random = seedrandom(seed)
  const coordinates = []

  for (let i = 0; i < pointsCount; ++i) {
    let randomPoint = [
      randomInt(randomInt(0, viewBoxWidth), viewBoxWidth * random()),
      randomInt(randomInt(0, viewBoxHeight), viewBoxHeight * random())
    ]
    coordinates.push(randomPoint)
  }

  const pointsInt = randomInt(0, 100)

  let pointsRangeMax = 8
  if (pointsInt > 70) {
    pointsRangeMax = randomInt(4, 32)
  } else if (pointsInt > 85) {
    pointsRangeMax = randomInt(32, 256)
  } else {
    pointsRangeMax = randomInt(256, 9000)
  }

  const div = {
    pointsCount: pointsCount,
    pointsRangeMax: pointsRangeMax,
    coordinates: coordinates,
    color: color,
    parentBg: parentBg,
    viewBoxWidth: viewBoxWidth,
    viewBoxHeight: viewBoxHeight,
    strokeWidth: 1,
    borderWidth: randomInt(0, 8),
    pointStrokeWidth: sample([
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      3,
      4
    ]),
    mixBlendMode: 'none',
    width: width,
    height: height,
    xValue: 0,
    yValue: 0,
    dashArray1: 0,
    dashArray2: 0,
    dashArray3: 0,
    dashArray4: 0,
    strokeOpacity: 100
  }

  return {
    ...div
  }
}

module.exports = generateDiv
